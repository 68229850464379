<template>
  <div>
    <template v-if="classes && classes.length > 0">
      <div class="text-center mt-3">
        <el-pagination
          background
          @current-change="setPage"
          :page-size="pageSize"
          :current-page="page"
          :total="resultsTotal"
          :pager-count="9"
          layout="prev, pager, next"
        >
        </el-pagination>
      </div>
      <div class="classes-box">
        <div
          v-for="(course, index) in classes"
          :key="index"
          style="width: 100%;padding: 0.5rem;"
        >
          <router-link
            :to="{
              name: routerName,
              params: { id: course.id }
            }"
            style="width: 100%;text-decoration: none;"
          >
            <el-card
              class="course-card"
              :body-style="{ padding: '0px' }"
              shadow="hover"
            >
              <div class="card-cover">
                {{ course.name.substr(0, 3) }}
              </div>
              <div style="width: 100%;padding: 14px;">
                <h5 class="class-title">
                  {{ course.name }}
                </h5>
                <div class="description">
                  {{ course.description }}
                </div>
                <div class="bottom clearfix">
                  <time class="time">
                    {{ $t("TeacherCourses.Number of students") }}:
                    {{ course.student_users_count }}
                  </time>
                  <router-link
                    :to="{
                      name: routerName,
                      params: { id: course.id }
                    }"
                  >
                    <el-button type="text" class="button">
                      {{ $t("TeacherCourses.View Class") }}
                    </el-button>
                  </router-link>
                </div>
              </div>
            </el-card>
          </router-link>
        </div>
      </div>
      <div class="text-center mb-3">
        <el-pagination
          background
          @current-change="setPage"
          :page-size="pageSize"
          :current-page="page"
          :total="resultsTotal"
          :pager-count="9"
          layout="prev, pager, next"
        >
        </el-pagination>
      </div>
    </template>
    <el-empty v-else description="No Data"></el-empty>
  </div>
</template>

<script>
/*eslint-disable no-unused-vars */
import { mapState, mapGetters, mapActions } from "vuex";
/*eslint-enable */

export default {
  components: {},

  mixins: [],

  props: ["classes", "page", "resultsTotal", "pageSize", "routerName"],
  data() {
    return {};
  },
  computed: {},
  watch: {},

  mounted() {},

  methods: {
    setPage(page) {
      this.routerPush({
        params: { ...this.$route.params },
        query: { ...this.$route.query, page: page }
      });
    }
  }
};
</script>

<style scoped>
.course-card {
  cursor: pointer;
}
.card-cover {
  /* background-color: var(--themeColor);
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  aspect-ratio: 16 / 9;
  font-size: 4rem;
  font-weight: 900;
  padding: 1rem;
  overflow: hidden;
  white-space: nowrap; overflow: hidden; text-overflow: ellipsis; */
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  background-color: var(--themeColor);
  position: relative;
  width: 100%;
  aspect-ratio: 16 / 9;
  padding: 1rem;
  font-size: 4rem;
  line-height: 5.5rem;
  overflow: hidden;
  text-align: center;
  font-weight: 900;
  white-space: nowrap;
}
.class-title {
  margin-bottom: 0.5rem;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-size: 1rem;
}
.description {
  height: 1rem;
  line-height: 1rem;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  color: #9499a0;
  white-space: pre-line;
  font-size: 0.8rem;
}

.time {
  font-size: 13px;
  color: #999;
}

.bottom {
  margin-top: 13px;
  line-height: 12px;
}

.button {
  padding: 0;
  float: right;
}

.image {
  width: 100%;
  display: block;
}

.clearfix:before,
.clearfix:after {
  display: table;
  content: "";
}

.clearfix:after {
  clear: both;
}

.classes-box {
  display: grid;
}

@media (min-width: 1560px) and (max-width: 2059.9px) and (min-width: 1560px) and (max-width: 2059.9px) {
  .classes-box {
    grid-template-columns: repeat(5, 1fr);
  }
}

@media (min-width: 1400px) and (max-width: 1559.9px) and (min-width: 1400px) and (max-width: 1559.9px) {
  .classes-box {
    grid-template-columns: repeat(5, 1fr);
  }
}
@media (min-width: 1300px) and (max-width: 1399.9px) and (min-width: 1300px) and (max-width: 1399.9px) {
  .classes-box {
    grid-template-columns: repeat(4, 1fr);
  }
}
@media (min-width: 1140px) and (max-width: 1299.9px) and (min-width: 1140px) and (max-width: 1299.9px) {
  .classes-box {
    grid-template-columns: repeat(4, 1fr);
  }
}
@media (max-width: 1139.9px) and (max-width: 1139.9px) {
  .classes-box {
    grid-template-columns: repeat(3, 1fr);
  }
}
@media (max-width: 768px) and (max-width: 768px) {
  .classes-box {
    grid-template-columns: repeat(1, 1fr);
  }
}
</style>
